import React, { Fragment, useContext, useEffect, useReducer, useState } from 'react';
import PropType from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import HelpSectionReducer from '../../state-management/reducers/HelpSectionReducer';
import UserContext from '../../state-management/context/UserContext';
import { SET_SESSION_EXPIRED, UPDATE_INDEX_RESULTS, UPDATE_SUCCESS_SCREEN } from '../../state-management/actions/actionTypes';

// Components
import ContactForm from '../shared/ContactForm';
import HelpSectionArticleList from './HelpSectionArticleList';
import HelpSectionFAQ from './HelpSectionFAQ';
import HelpSectionSearch from './HelpSectionSearch';
import HelpSectionSelectedArticle from './HelpSectionSelectedArticle';
import Navbar from '../shared/Navbar';
import Results from '../results/Results';

// Constants
import api from '../../consts/api';
import helpSectionViews from '../../consts/helpSectionViews';
import results from '../../consts/results';
import HelpSectionNeedToSpeakToSomeone from './HelpSectionNeedToSpeakToSomeone';

const HelpSectionScreen = ({ match }) => {
    const initialHelpSectionState = {
        currentView: helpSectionViews.HELP_CENTER,
        isContactUsResultVisible: false,
        filteredArticles: [],
        searchText: '',
        selectedArticle: null,
    };

    // Context variables
    const helpSectionReducer = (state, action) => HelpSectionReducer(state, action);
    const [helpSectionState, helpSectionDispatch] = useReducer(helpSectionReducer, initialHelpSectionState);
    // eslint-disable-next-line no-unused-vars
    const [userState, userDispatch] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    // State variables
    const [errorMessage, setErrorMessage] = useState('');

    // Refresh the users token on each navigation
    useEffect(() => {
        api.get('/users/refresh');
    }, [helpSectionState.selectedArticle])

    useEffect(() => {
        window.scroll(0, 0);
    }, [helpSectionState.currentView])

    /**
     * Submit the user information payload
     *
     * @param {string} text the payload to be sent
     */
    const submitContactUsRequest = async (text) => {
        try {
            await api.post(`new-hires/contact-us`, { informationFromCandidate: text });

            helpSectionDispatch({
                type: UPDATE_SUCCESS_SCREEN,
                isContactUsResultVisible: true,
            });

            userDispatch({
                type: UPDATE_INDEX_RESULTS,
                result: results.PENDING_RESPONSE,
            });
        } catch (error) {
            // Session expired
            if (error.response.status === 403) {
                userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
            }

            setErrorMessage(t('errors.request-error'));
        }
    };

    /**
     * Need to bring the translations into this object so they can be searched through
     * (doesn't seem possible to iterate through with useTranslation).
     */
    const translatedArticles = {
        popular: [
            {
                description: <Trans i18nKey="help.article-list.popular.1.description"/>,
                subtext: t('help.article-list.popular.1.subtext'),
                title: t('help.article-list.popular.1.title')
            },
            {
                description: t('help.article-list.popular.2.description'),
                subtext: t('help.article-list.popular.2.subtext'),
                title: t('help.article-list.popular.2.title')
            },
            {
                description: <Trans i18nKey="help.article-list.popular.3.description"/>,
                subtext: t('help.article-list.popular.3.subtext'),
                title: t('help.article-list.popular.3.title')
            },
            {
                description: t('help.article-list.popular.4.description'),
                subtext: t('help.article-list.popular.4.subtext'),
                title: t('help.article-list.popular.4.title')
            },
            {
                description: <Trans i18nKey="help.article-list.popular.5.description"/>,
                subtext: t('help.article-list.popular.5.subtext'),
                title: t('help.article-list.popular.5.title')
            },
            {
                description: <Trans i18nKey="help.article-list.popular.6.description"/>,
                subtext: t('help.article-list.popular.6.subtext'),
                title: t('help.article-list.popular.6.title')
            }
        ],
        personal: [
            {
                description: t('help.article-list.personal.6.description'),
                subtext: t('help.article-list.personal.6.subtext'),
                title: t('help.article-list.personal.6.title')
            },
            {
                description: t('help.article-list.personal.7.description'),
                subtext: t('help.article-list.personal.7.subtext'),
                title: t('help.article-list.personal.7.title')
            },
            {
                description: t('help.article-list.personal.8.description'),
                subtext: t('help.article-list.personal.8.subtext'),
                title: t('help.article-list.personal.8.title')
            },
            {
                description: t('help.article-list.personal.9.description'),
                subtext: t('help.article-list.personal.9.subtext'),
                title: t('help.article-list.personal.9.title')
            },
            {
                description: t('help.article-list.personal.10.description'),
                subtext: t('help.article-list.personal.10.subtext'),
                title: t('help.article-list.personal.10.title')
            }
        ],
        documents: [
            {
                description: <Trans i18nKey="help.article-list.documents.11.description"/>,
                subtext: t('help.article-list.documents.11.subtext'),
                title: t('help.article-list.documents.11.title')
            },
            {
                description: <Trans i18nKey="help.article-list.documents.12.description"/>,
                subtext: t('help.article-list.documents.12.subtext'),
                title: t('help.article-list.documents.12.title')
            },
            {
                description: t('help.article-list.documents.13.description'),
                subtext: t('help.article-list.documents.13.subtext'),
                title: t('help.article-list.documents.13.title')
            },
            {
                description: t('help.article-list.documents.14.description'),
                subtext: t('help.article-list.documents.14.subtext'),
                title: t('help.article-list.documents.14.title')
            }
        ],
        next: [
            {
                description: <Trans i18nKey="help.article-list.next.15.description"/>,
                subtext: t('help.article-list.next.15.subtext'),
                title: t('help.article-list.next.15.title')
            },
            {
                description: t('help.article-list.next.16.description'),
                subtext: t('help.article-list.next.16.subtext'),
                title: t('help.article-list.next.16.title')
            },
            {
                description: <Trans i18nKey="help.article-list.next.17.description"/>,
                subtext: t('help.article-list.next.17.subtext'),
                title: t('help.article-list.next.17.title')
            },
            {
                description: <Trans i18nKey="help.article-list.next.18.description"/>,
                subtext: t('help.article-list.next.18.subtext'),
                title: t('help.article-list.next.18.title')
            }
        ]
    };

    return (
        <HelpSectionContext.Provider value={[helpSectionState, helpSectionDispatch]}>
            <Navbar match={match} />
            <div className="gov__container">
                {
                    helpSectionState.isContactUsResultVisible
                    ?   <Results />
                    :   <Fragment>
                            {
                                helpSectionState.currentView === helpSectionViews.HELP_CENTER
                                && !helpSectionState.searchText
                                && !helpSectionState.selectedArticle
                                && <h1 className="gov__help-section-title">{t('help.title')}</h1>
                            }

                            { helpSectionState.currentView !== helpSectionViews.CONTACT_US && <HelpSectionSearch articles={translatedArticles} /> }

                            {
                                helpSectionState.searchText
                                ?   <div className="gov__search-filter">
                                        <h1 className="gov__help-section-title">{t('help.search.results-for')} "{helpSectionState.searchText}"</h1>

                                        <div className="gov__filtered-by">
                                            <span>{t('help.search.filter')}</span>
                                            <span>{helpSectionState.filteredArticles.length} {t('help.search.of')} {helpSectionState.filteredArticles.length}</span>
                                        </div>

                                        <HelpSectionArticleList
                                            iconClass="gov__lightbulb"
                                            title={t('help.search.title')}
                                            type={helpSectionState.filteredArticles}
                                        />
                                    </div>
                                :   helpSectionState.selectedArticle
                                    ?   <HelpSectionSelectedArticle />
                                    :   <Fragment>
                                            {
                                                helpSectionState.currentView === helpSectionViews.HELP_CENTER &&
                                                <Fragment>
                                                    <HelpSectionArticleList
                                                        iconClass="gov__lightbulb"
                                                        title={t('help.article-list.popular.title')}
                                                        type={translatedArticles.popular}
                                                    />
                                                    <HelpSectionFAQ />
                                                    <HelpSectionNeedToSpeakToSomeone />
                                                </Fragment>
                                            }

                                            {
                                                helpSectionState.currentView === helpSectionViews.PERSONAL_INFORMATION &&
                                                <Fragment>
                                                    <h1 className="gov__help-section-title">{t('help.faq.personal-information')}</h1>

                                                    <HelpSectionArticleList
                                                        iconClass="gov__faq-profile-icon"
                                                        title={t('help.article-list.personal.title')}
                                                        type={translatedArticles.personal}
                                                    />
                                                </Fragment>
                                            }

                                            {
                                                helpSectionState.currentView === helpSectionViews.DOCUMENTS &&
                                                <Fragment>
                                                    <h1 className="gov__help-section-title">{t('help.faq.documents')}</h1>

                                                    <HelpSectionArticleList
                                                        iconClass="gov__faq-document-icon"
                                                        title={t('help.article-list.documents.title')}
                                                        type={translatedArticles.documents}
                                                    />
                                                </Fragment>
                                            }

                                            {
                                                helpSectionState.currentView === helpSectionViews.NEXT_STEPS &&
                                                <Fragment>
                                                    <h1 className="gov__help-section-title">{t('help.faq.next-steps')}</h1>

                                                    <HelpSectionArticleList
                                                        iconClass="gov__faq-next-steps-icon"
                                                        title={t('help.article-list.next.title')}
                                                        type={translatedArticles.next}
                                                    />
                                                </Fragment>
                                            }

                                            {
                                                helpSectionState.currentView === helpSectionViews.CONTACT_US &&
                                                <Fragment>
                                                    <ContactForm
                                                        errorMessage={errorMessage}
                                                        onSubmit={submitContactUsRequest}
                                                        title={t('help.contact-us.form.title')}
                                                        subtext={t('help.contact-us.form.description')}
                                                    />
                                                </Fragment>
                                            }
                                        </Fragment>
                            }
                        </Fragment>
                }
            </div>
        </HelpSectionContext.Provider>
    );
};

HelpSectionScreen.propTypes = {
    match: PropType.object,
};

HelpSectionScreen.defaultProps = {
    match: {},
};

export default HelpSectionScreen;
