import React, { Fragment, useState, useContext } from 'react';
import Button from '../shared/Button';
import api from '../../consts/api';
import { SET_LOADING } from '../../state-management/actions/actionTypes';
import UserContext from '../../state-management/context/UserContext';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import DocumentUploadInput from './DocumentUploadInput';
import DocumentRemoveInput from './DocumentRemoveInput';

const COMPRESSION_OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: undefined,
  useWebWorker: true,
};

const compress = (uncompressedFile) => {
  return new Promise((resolve, reject) => {
    console.log('compressing');
    imageCompression(uncompressedFile, COMPRESSION_OPTIONS)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          if (compressedFile) {
            console.log({ name: uncompressedFile.name, originalsize: uncompressedFile.size, compressedsize: compressedFile.size, });
            resolve(reader.result.split(',')[1]);
          }
        };
        reader.readAsDataURL(compressedFile);
      })
      .catch((e) => reject(e));
  });
};

// Construct custom document sides list
const createDocumentSidesList = (document) => {
  console.log('createDocumentSidesList');
  return [...Array(document.sides).keys()].map(k => {
    const side = document.sides === 1 ? "front" : document.sides === 2 && k === 0 ? "front" : document.sides === 2 && k === 1 ? "back" : k
    const buttonText = (document.sides === 1 ? "choose file" : document.sides === 2 ? `choose ${side} side` : `choose page ${k}`).toUpperCase()
    return {
      sideId: k,
      compressedImage: null,
      fileName: null,
      file: null,
      buttonText,
      data: {
        side,
        rulesEngineId: document.id,
        image: null,
        supported: false,
      }
    }
  })
}

const createPayload = (documents) => documents.map(doc => ({ ...doc.data, image: doc.compressedImage }));

const DocumentUpload = ({ document, updateDocumentIndex }) => {
  // eslint-disable-next-line no-unused-vars
  const [userState, userDispatch] = useContext(UserContext);
  const [documentSidesList, setDocumentSidesList] = useState(createDocumentSidesList(document));
  const [processing, setProcessing] = useState(false);
  const [errorSides, setErrorSides] = useState([]);
  const [uploadError, setUploadError] = useState(false);

  const handleCapture = async (event, sideId) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentSidesList(documentSidesList.map((docSide) => {
        if (sideId === docSide.sideId) {
          docSide.fileName = file.name;
          docSide.file = file
        }
        return docSide;
      }));
    }

    event.target.value = null;
  };

  const handleUpload = async () => {
    console.log('handleUpload for', document);
    setErrorSides([]);
    setProcessing(true);
    setUploadError(false);

    let tempDocumentSidesList = [...documentSidesList];
    const errors = [];

    for (const documentSide of tempDocumentSidesList) {
      try {
        documentSide.compressedImage = await compress(documentSide.file);
      } catch (e) {
        console.log(e);
        errors.push((documentSide.data.side + " side").toUpperCase());
        documentSide.fileName = null;
        documentSide.file = null;
        documentSide.compressedImage = null;
      }
    }
    
    if (tempDocumentSidesList.some(doc => !doc.compressedImage?.length)) {
      setDocumentSidesList(tempDocumentSidesList);
      setErrorSides(errors);
      setProcessing(false);
    } else {
      userDispatch({ type: SET_LOADING, isLoading: true });

      api.post('new-hires/unsupported-document', {
        applicantId: '',
        documents: createPayload(tempDocumentSidesList),
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        // TODO: Only upload if successful, currently if response === true - this should be changed to response.status === 201
        console.log(response);
        // TODO: Update current document index to move to the next document
        updateDocumentIndex();
        if (userState.documentIndex < userState.selectedDocumentCombination.documents.length - 1) {
          userDispatch({ type: SET_LOADING, isLoading: false });
        }
      }).catch((error) => {
        console.log(error);
        userDispatch({ type: SET_LOADING, isLoading: false });
        setProcessing(false);
        setUploadError(true);
      });
    }
  };

  const handleRemoveFile = (sideId) => {
    setDocumentSidesList(documentSidesList.map((docSide) => {
      if (sideId === docSide.sideId) {
        docSide.fileName = null;
        docSide.file = null
      }
      return docSide;
    }));
  };

  return (
    <Fragment>
      <div className="gov__document-upload-container">
        <div className="gov__document-upload-content">
          <h1 className="gov__document-upload-title">{document.name}</h1>
          <p className="gov__document-upload-description">
            {document.description}
          </p>
          <div className="gov__document-upload-central-container">
            <div className="onfido-sdk-ui-Theme-icon onfido-sdk-ui-Uploader-identityIcon img-div"></div>
            <div>
              {documentSidesList.filter(docSide => docSide.compressedImage == null || docSide.compressedImage == '').map((docSide, i) => {
                return <DocumentUploadInput key={`side-${i}`} documentObject={docSide} title={docSide.buttonText} sideId={docSide.sideId} handleCapture={handleCapture} />
              })}
              <p
                className="gov__document-upload-note
              "
              >
                *Must be in picture format (eg: PNG, JPEG). If your document is
                in a different format (eg: PDF), please convert this to a
                picture format before uploading
              </p>
            </div>

            {documentSidesList.filter(docSide => docSide.fileName != null && [null, undefined, ''].indexOf(docSide.compressedImage) !== -1).map((docSide, i) => {
              return <DocumentRemoveInput key={`file-${i}`} sideId={docSide.sideId} handleRemoveFile={handleRemoveFile} fileName={docSide.fileName} />
            })}
          </div>

          {!!errorSides.length && <div className='process-error'>
            <p>
              An error occured while trying to process the following files:
            </p>
            <ul>
              {errorSides.map((es, i) => {
                return <li key={`err-${i}`}>{es}</li>
              })}
            </ul>
            <p>
              Please try again or select different files and then re-submit.
            </p>
          </div>}

          {uploadError && <div className='process-error'>
            <p>
              An error occured while trying to upload your files:
            </p>
            <p>
              Please try again or select different files and then re-submit.
            </p>
          </div>}

          <div className="gov__document-upload-footer">
            {processing ? <div className="gov__btn gov__document-input-loading">
              <div className="gov__loading-container">
                <div className={'gov__loading-spinner-container'}>
                  <div></div>
                </div>
              </div>
            </div> :
              <Button
                classBtn="gov__document-upload-action-button"
                onClickHandler={handleUpload}
                text={processing ? "PLEASE WAIT..." : "SUBMIT"}
                isDisabled={documentSidesList.some(docSide => !docSide.file) || processing}
              />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DocumentUpload.propTypes = {
  document: PropTypes.object,
  updateDocumentIndex: PropTypes.func,
};

export default DocumentUpload;