import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import { SELECT_VIEW } from '../../state-management/actions/actionTypes';

// Components
import Icon from '../shared/Icon';

// Consts
import helpSectionViews from '../../consts/helpSectionViews';

const HelpSectionFAQ = () => {
    // Context variables
    // eslint-disable-next-line no-unused-vars
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <div className="gov__faq-container">
            <p className="gov__topic-title">{t('help.faq.title')}</p>

            <div
                className="gov__faq-topic"
                role="button"
                aria-pressed="false"
                onClick={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.PERSONAL_INFORMATION })}
            >
                <div className="gov__faq-icon">
                    <Icon icon="gov__faq-profile-icon" label="Person icon" />
                    <h4>{t('help.faq.personal-information')}</h4>
                </div>
                <Icon icon="gov__chevron right" label="Arrow right" />
            </div>
            <div
                className="gov__faq-topic"
                role="button"
                aria-pressed="false"
                onClick={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.DOCUMENTS })}
            >
                <div className="gov__faq-icon">
                    <Icon icon="gov__faq-document-icon" label="Document icon" />
                    <h4>{t('help.faq.documents')}</h4>
                </div>
                <Icon icon="gov__chevron right" label="Arrow right" />
            </div>
            <div
                className="gov__faq-topic"
                role="button"
                aria-pressed="false"
                onClick={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.NEXT_STEPS })}
            >
                <div className="gov__faq-icon">
                    <Icon icon="gov__faq-next-steps-icon" label="Next steps icon" />
                    <h4>{t('help.faq.next-steps')}</h4>
                </div>
                <Icon icon="gov__chevron right" label="Arrow right" />
            </div>
        </div>
    );
};

export default HelpSectionFAQ;
