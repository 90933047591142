import React from "react";
import PropTypes from 'prop-types';

const DocumentUploadInput = ({ documentObject, title, sideId, handleCapture }) => {
    return <>
        <label
            htmlFor={`document-picture-${sideId}`}
            className={`gov__label-btn ${documentObject.file ? 'disabled' : ''
                }`}
        >
            {title}
        </label>
        <input
            type="file"
            id={`document-picture-${sideId}`}
            name={`document-picture-${sideId}`}
            accept="image/*"
            onChange={(event) => {
                handleCapture(event, sideId);
            }}
            disabled={!documentObject.file === null}
        />

        <p
            className="gov__document-upload-note"
        ></p>
    </>
};

DocumentUploadInput.propTypes = {
    documentObject: PropTypes.object,
    title: PropTypes.string,
    sideId: PropTypes.number,
    handleCapture: PropTypes.func,
};


export default DocumentUploadInput;