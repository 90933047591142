import React, { Fragment, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../../consts/api';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
  INCREMENT_INDEX,
  SET_LOADING,
} from '../../state-management/actions/actionTypes';

// Components
import Button from '../shared/Button';
import Modal from '../shared/Modal';

const TermsAndConditions = () => {
  // Context variables
  // eslint-disable-next-line no-unused-vars
  const [userState, userDispatch] = useContext(UserContext);
  const navigate = useNavigate();

  // Translation hook
  const { t } = useTranslation();

  // State variables
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkForSanctionedCountry = async () => {
    userDispatch({ type: SET_LOADING, isLoading: true });

    // Call GeoIP2 Service to check if country is sanctioned
    api
      .get('/geolocation')
      .then((responseFromGeoIP2) => {
        console.log('geolocation resolve');
        if (responseFromGeoIP2.data === false) {
          console.log('not blocked');
          userDispatch({ type: SET_LOADING, isLoading: false });
          userDispatch({ type: INCREMENT_INDEX });
        } else if (responseFromGeoIP2.data === '') {
          console.log('before setLoading');
          setLoading(false);
          throw 'Could not get ip address';
        } else {
          console.log('should redirect to geoblocked');
          userDispatch({ type: SET_LOADING, isLoading: false });
          setLoading(false);
          navigate('/geoblocked');
        }
      })
      .catch((error) => {
        // Issue somewhere between client and GeoIP2 service - try using browser location
        console.log('Browser location flow', error);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            api
              .post(`/geolocation/maps`, {
                latitude: latitude,
                longitude: longitude,
              })
              .then((responseFromMapsAPI) => {
                if (responseFromMapsAPI.data === false) {
                  userDispatch({ type: SET_LOADING, isLoading: false });
                  userDispatch({ type: INCREMENT_INDEX });
                } else {
                  userDispatch({ type: SET_LOADING, isLoading: false });
                  navigate('/geoblocked');
                }
              })
              .catch((error) => {
                userDispatch({ type: SET_LOADING, isLoading: false });
                navigate('/geoblocked');
                console.log(error);
              });
          });
        } else {
          userDispatch({ type: SET_LOADING, isLoading: false });
          navigate('/geoblocked');
        }
      });
  };

  return (
    <Fragment>
      {isModalOpen && (
        <Modal updateModal={(event) => setIsModalOpen(event)}>
          <div className="gov__modal-header">
            <div className="gov__modal-banner">
              Deloitte<span>.</span>
            </div>
            <div
              className="gov__modal-close"
              role="button"
              aria-pressed="false"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </div>
          </div>
          <div className="gov__modal-content">
            <p className="gov__modal-subtitle">GO VERIFY PRIVACY SUMMARY</p>
            <p>Last revised: [26] March 2020</p>

            <h1>Privacy Summary</h1>
            <p>
              This statement covers your use of the Right to Work platform - Go
              Verify.
            </p>
            <p>
              The data controller for this platform is a Deloitte LLP with
              registered office 1 New Street Square, London, EC4A 3HQ, using an
              identity verification technology powered by Onfido.
            </p>
            <p>
              Deloitte is committed to protecting your information by handling
              it responsibly and safeguarding it using appropriate technical,
              administrative and physical security measures.
            </p>

            <p className="gov__bold">Information about you which we process</p>
            <p>
              We may collect or obtain information about you that you provide to
              us, that we obtain from third parties or that is publicly
              available, or which we infer from the way you interact with us.
            </p>
            <p className="gov__bold">This information about you may include:</p>
            <p>- National / Government Identification number</p>
            <p>- First and Last Name</p>
            <p>- Passport number</p>
            <p>- Date of Birth</p>
            <p>
              - Driver's license number (or other government-issued
              identification number)
            </p>
            <p>- Photographic image</p>
            <p>- Issuance/expiry date</p>
            <p>- IP Address</p>
            <p>
              Where we use Onfido to process your photographic image in order to
              verify your identity, it will also include biometric data
              considered a ‘special category’ of information about you.
            </p>

            <p className="gov__bold">How we use information about you</p>
            <p>
              Deloitte may process information about you to verify your identity
              in order to determine your right to work in your country of
              assignment, following national immigration rules.
            </p>

            <p>
              We also use your IP address to determine if we are legally
              permitted to provide the services to you in your location.
            </p>

            <p>
              Document authenticity and ID verification services are used only
              for identity and document authenticity checks and do not determine
              right to work.
            </p>

            <p className="gov__bold">Legal ground for processing</p>
            <p>
              Our legal ground for processing this data is our legitimate
              interest as a provider of tax and legal services in advising our
              clients on your right to work in the United Kingdom. We will seek
              your consent only in respect of the processing of special category
              or sensitive information.
            </p>

            <p className="gov__bold">Sharing your personal data</p>
            <p>
              In connection with any of the purposes outlined in the “How we use
              your personal data?” section above, we may ask to disclose your
              data to the following and/or ask that they process your data on
              our behalf:
            </p>
            <p>
              - your prospective employer and/or its advisers, or your advisers;
            </p>
            <p>
              - competent authorities (including courts and authorities
              regulating us and other members of the Deloitte Network);
            </p>
            <p>- online cloud platforms providing hosting services to us;</p>
            <p>- providers of IP geolocation services;</p>
            <p>
              - fraud prevention databases and law enforcement bodies (if we
              suspect that the identity documents you have provided are
              fraudulent), and
            </p>
            <p>
              - providers of identity verification services (such as Onfido).
            </p>

            <p className="gov__bold">
              Transferring your personal data outside the UK
            </p>
            <p>
              Information we hold about you may be transferred to other
              countries (which may include countries outside the European
              Economic Area (“EEA”) where the third parties described above
              conduct their activities.
            </p>
            <p>
              When we, or our permitted third parties, transfer your personal
              data outside the EEA, we will impose contractual obligations on
              the recipients of that data to protect your personal data to the
              standard required in the EEA. They or we may also require the
              recipient to subscribe to international frameworks intended to
              enable secure data sharing.
            </p>
            <p>We may also transfer your personal data when:</p>
            <p>
              - the transfer is to a country deemed to provide adequate
              protection of your personal data by the European Commission; or
            </p>
            <p>- where you have consented to the transfer.</p>

            <p className="gov__bold">Protecting your personal data</p>
            <p>
              We use a range of measures to ensure we keep your personal data
              secure, accurate and up to date. These include:
            </p>
            <p>
              - education and training to relevant staff to ensure they are
              aware of our privacy obligations when handling personal data;
            </p>
            <p>
              - administrative and technical controls to restrict access to
              personal data to a ‘need to know’ basis;
            </p>
            <p>
              - technological security measures, including fire walls,
              encryption and anti- virus software; and
            </p>
            <p>
              - physical security measures, such as security passes to access
              our premises.
            </p>
            <p>
              The transmission of data over the internet (including by e-mail)
              is never completely secure. Therefore, although we use appropriate
              measures to try to protect personal data, we cannot guarantee the
              security of data transmitted to us or by us.
            </p>

            <p className="gov__bold">How long we keep your personal data for</p>
            <p>
              We seek to ensure that we only keep your personal data for the
              longest of:
            </p>
            <p>- the period necessary for the relevant activity or services;</p>
            <p>- any retention period that is required by law; or</p>
            <p>
              - the period in which litigation or investigations might arise in
              respect of the services.
            </p>

            <p className="gov__bold">Your rights</p>
            <p>
              You have various rights in relation to your personal data. In
              particular, you have a right to:
            </p>
            <p>
              - obtain confirmation that we are processing your personal data
              and request a copy of the personal data we hold aboutyou;
            </p>
            <p>
              - be informed about the processing of your personal data (i.e. for
              what purposes, what types, to what recipients it is disclosed,
              storage periods, any third party sources from where it was
              obtained, confirmation of whether we undertake automated
              decision-making, including profiling, and the logic, significance
              and envisaged consequences);
            </p>
            <p>
              - ask that we update the personal data we hold about you, or
              correct such personal data that you think is incorrect or
              incomplete;
            </p>
            <p>
              - ask that we delete personal data that we hold about you, or
              restrict the way in which we use such personal data; withdraw
              consent to our processing of&nbsp;&nbsp;your personal data (to the
              extent such processing is based on previously obtained consent);
            </p>
            <p>
              - receive a copy of the personal data concerning you, which you
              have provided to us, in a structured, commonly used and
              machine-readable format and to transmit such personal data to
              another party (to the extent the processing is based on consent or
              a contract); and
            </p>
            <p>- object to our processing of your personal data.</p>
            <p>
              If you would like to access or see a copy of your personal data,
              you must ask us in writing. We will endeavour to respond within a
              reasonable period, and in any event within one month in line with
              Data Protection Legislation. We will comply with our legal
              obligations as regards your rights as a data subject.
            </p>

            <p className="gov__bold">Right to complain</p>
            <p>
              If you wish to raise a complaint about how we are using your
              information, exercise any of the rights set out above, or if you
              have any questions or comments about privacy issues, you can
              contact us by
            </p>
            <p className="gov__bold">
              - writing to Data Protection Officer, Deloitte LLP at 1 New Street
              Square, London EC4A 3HQ; or
            </p>
            <p className="gov__bold">
              - sending an email&nbsp;&nbsp;to&nbsp;&nbsp;DPO@deloitte.co.uk.
            </p>
            <p className="gov__bold">
              You can also complain to the Information Commissioner’s Office,
              which regulates and supervises the use of personal data in the UK,
              on 0303 123 1113. If you are not based in the UK, you have a right
              to complain to the EU Data Protection Authority (“DPA”) in your
              jurisdiction.
            </p>
            <p>
              Deloitte LLP is a limited liability partnership registered in
              England and Wales with registered number OC303675 and its
              registered office at 1 New Street Square, London, EC4A 3HQ, United
              Kingdom.
            </p>
            <p>
              Deloitte LLP is the United Kingdom affiliate of Deloitte NSE LLP,
              a member firm of Deloitte Touche Tohmatsu Limited, a UK private
              company limited by guarantee (“DTTL”). DTTL and each of its member
              firms are legally separate and independent entities. DTTL and
              Deloitte NSE LLP do not provide services to clients. Please see
              www.deloitte.com/about to learn more about our global network of
              member firms.
            </p>
            <p>
              Deloitte LLP is authorised and regulated by the Solicitors
              Regulation Authority (SRA) to provide certain legal services
              (licence number: 646135). Deloitte Legal means the legal practices
              of Deloitte Touche Tohmatsu Limited member firms or their
              affiliates that provide legal services. In the UK, Deloitte Legal
              covers both legal advisory (authorised and regulated by the SRA)
              and non-SRA regulated legal consulting services. For legal,
              regulatory and other reasons not all member firms provide legal
              services.
            </p>
            <p>© 2020 Deloitte LLP. All rights reserved.</p>
          </div>
        </Modal>
      )}

      <div className="gov__terms-and-conditions-container">
        <div className="gov__terms-and-conditions-title">
          <h1>{t('terms-and-conditions.title')}</h1>

          <p>
            <Trans
              i18nKey="terms-and-conditions.subtitle"
              components={{
                underline: (
                  <span
                    role="button"
                    aria-pressed="false"
                    onClick={() => setIsModalOpen(true)}
                  />
                ),
              }}
            />
          </p>
        </div>

        <div className="gov__divider"></div>

        <div className="gov__terms-and-conditions-biometric-section">
          <p className="gov__biometric-title">
            {t('terms-and-conditions.biometric-info.title')}
          </p>
          <div className="gov__terms-and-conditions-subtext">
            <p>
              <Trans i18nKey="terms-and-conditions.biometric-info.content" />
            </p>
          </div>
        </div>

        <div className="gov__terms-and-conditions-footer">
          <div>
            <div className="gov__consent-text">
              <p>
                <Trans
                  i18nKey="terms-and-conditions.consent-information"
                  components={{
                    a: (
                      <a
                        href="https://onfido.com/facial-scan-policy-and-release/"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    italic: <i />,
                    ins: <ins />,
                  }}
                />
              </p>
            </div>
            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox" />
                <span
                  className="gov__checkmark"
                  role="button"
                  aria-pressed="false"
                  aria-label="Checkmark icon"
                  onClick={() => setIsChecked(!isChecked)}
                />
              </label>
            </div>
          </div>

          <Button
            isDisabled={!isChecked || loading}
            onClickHandler={checkForSanctionedCountry}
            text={t('terms-and-conditions.accept-and-start')}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TermsAndConditions;
